import React from "react";
import PricingNavbar from "../Pricing/PricingNavbar/PricingNavbar";
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter";
import IFrameComponent from "../IFrameComponent";
import FAQComponent from "../FAQ/FAQComponent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogItems from "./BlogItems";
import useSiteMetadata from "../SiteMetaData";
import {openPopupWidget} from "react-calendly";

const DemoVideo = () => {
  const {dashboardRegisterUrl, calendlyDemoUrl} = useSiteMetadata()

  const bookDemo = () => openPopupWidget({ url: calendlyDemoUrl })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoPlay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return(
      <div className="FAQCol">
        <PricingNavbar />
        <div className="container p-0">
          <div className="columns is-multiline is-mobile">
            <div className="column is-12">
              <h3 className="has-text-centered is-size-2-desktop is-size-3-tablet is-size-3-mobile has-text-black has-text-weight-bold">
                Demo Video
              </h3>
            </div>
            <div className="column is-12">
              <IFrameComponent />
            </div>
          </div>
          <div className={"has-text-centered"}>
            <div>
              <button
                  className={"button is-primary mx-4 is-uppercase is-medium has-text-weight-bold"}
                  onClick={()=>window.open(dashboardRegisterUrl, "_blank")}
              >
                start 14 day free trial
              </button>
              <button
                  className={"button is-primary is-outlined is-uppercase is-medium has-text-weight-bold"}
                  onClick={() => bookDemo()}
              >
                BOOK A DEMO WITH US
              </button>
              {/*              <label className={"is-primary"}>
                In-Person Online
              </label>*/}
              <div
                  className={"my-6"}
                  hidden
              >
                <Slider
                    {...settings}
                >
                  {BlogItems.map((b) => (
                      <a
                          key={String(b)}
                          href={b.link}
                          target={"_blank"}
                      >
                        <div
                            className={"px-6 has-text-centered"}
                        >
                          <img
                              src={b.image_url}
                              alt={b.title}
                              title={b.title}
                              className={"has-text-centered"}
                          />
                          <h3
                              className={"has-text-centered has-text-black"}
                          >
                            {b.title}
                          </h3>
                        </div>
                      </a>
                  ))}
                </Slider>
              </div>
            </div>
            <div>
            </div>
          </div>
          <div>
            <FAQComponent
              title={"Frequently Asked Questions"}
            />
          </div>
        </div>
        <div className="mt-6">
          <InfluencerFooter />
        </div>
      </div>
  )
}

export default DemoVideo