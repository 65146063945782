const BlogItems = [
  {
    title: "Lorem Ipsum #1",
    image_url: "https://influencerbit.com/blogs//static/aa11fe50225579e58e9f5d79f39cf4f4/cover4.png",
    link: "https://influencerbit.com/blogs/how-to-give-access-to-instagram-account/"
  },
  {
    image_url: "https://influencerbit.com/blogs//static/762dfb569fb00ee58fc991e02f490ef4/cover3.jpg",
    link: "https://influencerbit.com/blogs/profilebud-pricing/",
    title: "Lorem Ipsum dolor sit amet casrn sid anos \n" +
        "bach  #2"
  },
  {
    link: "https://influencerbit.com/blogs/daily-limits-with-profilebud/",
    image_url: "https://influencerbit.com/blogs//static/6b6c7eeafbd94aae05b184f85fd17bf0/cover3.png",
    title: "Lorem Ipsum #3"
  },
  {
    link: "https://influencerbit.com/blogs/how-to-rotate-instagram-accounts-to-reset-daily-limits/",
    image_url: "https://influencerbit.com/blogs//static/b56a79e4b7478fb11f2552f468c9d733/cover5.png",
    title: "title 22"
  }
]

export default BlogItems