import React from 'react'
import PageSeo from '../../components/SEO'
import DemoVideo from "../../components/DemoVideo";

function index() {
  return (
      <>
        <PageSeo pageTitle="Demo Video" />
        <DemoVideo />
      </>
  )
}

export default index
